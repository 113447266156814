import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 719.000000 697.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,697.000000) scale(0.100000,-0.100000)">

<path d="M2835 6963 c-498 -116 -822 -239 -1195 -451 -104 -59 -348 -216 -360
-231 -3 -3 -41 -33 -85 -66 -255 -189 -563 -510 -758 -790 -123 -175 -274
-443 -339 -600 -9 -22 -25 -60 -36 -85 -59 -139 -63 -159 -60 -321 3 -144 4
-151 16 -109 29 104 95 308 112 345 10 22 32 74 48 115 44 110 115 248 200
390 67 111 176 277 191 290 4 3 19 23 36 45 43 59 183 219 262 301 180 186
388 358 618 511 696 461 1586 693 2440 633 207 -14 296 -25 498 -61 120 -21
376 -89 527 -139 243 -80 527 -210 753 -344 82 -48 288 -185 297 -196 3 -3 21
-17 40 -30 19 -13 37 -27 40 -30 3 -3 27 -24 55 -45 229 -183 441 -401 615
-636 174 -234 245 -356 417 -719 l22 -45 0 112 1 113 -49 92 c-91 174 -139
253 -252 418 -39 57 -135 183 -161 211 -12 14 -45 52 -73 84 -53 63 -253 266
-320 326 -179 158 -455 354 -670 476 -175 98 -333 171 -585 268 -127 49 -217
75 -460 136 l-155 39 -805 -1 c-443 -1 -814 -3 -825 -6z"/>
<path d="M3400 6770 c-8 -5 -53 -9 -101 -9 -69 1 -95 -3 -128 -20 -52 -27
-114 -88 -140 -139 -83 -163 -16 -392 138 -473 55 -28 186 -38 270 -19 74 16
159 56 166 79 3 9 -4 44 -15 77 -25 75 -25 94 2 156 21 50 21 51 3 65 -11 7
-26 11 -35 8 -8 -4 -52 -11 -98 -17 -107 -14 -120 -31 -68 -89 27 -29 36 -49
38 -77 0 -20 2 -44 3 -52 2 -24 -26 -50 -55 -50 -77 0 -163 66 -201 154 -31
70 -32 186 -3 236 30 51 78 80 133 80 38 0 58 -8 113 -45 37 -25 74 -45 81
-45 39 0 43 48 9 115 -32 66 -73 90 -112 65z"/>
<path d="M3733 6763 c-4 -9 3 -33 16 -56 23 -39 23 -44 16 -221 -8 -206 -14
-249 -45 -281 -13 -14 -20 -33 -18 -47 3 -21 8 -23 68 -25 36 -1 126 -8 200
-14 180 -16 190 -16 222 20 25 29 48 86 48 124 0 23 -35 57 -60 57 -11 0 -33
-18 -52 -44 -47 -63 -90 -90 -130 -82 -18 3 -42 13 -55 21 -22 15 -23 21 -23
128 1 61 5 158 9 214 8 89 12 107 35 134 28 33 34 67 14 73 -7 3 -64 7 -126
10 -100 6 -113 4 -119 -11z"/>
<path d="M4638 6620 c-18 -16 -41 -30 -50 -30 -33 0 -104 -40 -146 -82 -61
-60 -85 -131 -80 -238 5 -92 22 -138 75 -196 68 -76 200 -117 305 -95 103 22
191 103 233 213 25 68 18 205 -15 273 -47 96 -166 185 -249 185 -30 0 -48 -7
-73 -30z m108 -129 c47 -29 65 -78 65 -176 0 -123 -24 -195 -75 -228 -48 -32
-126 -14 -158 35 -25 39 -41 126 -34 190 16 158 106 238 202 179z"/>
<path d="M2308 6539 c-11 -11 -31 -19 -50 -19 -50 0 -118 -40 -151 -87 -41
-58 -46 -123 -15 -183 33 -65 70 -80 225 -89 71 -4 139 -13 151 -20 29 -16 45
-57 37 -98 -4 -25 -15 -36 -50 -53 -53 -26 -91 -19 -159 30 -36 26 -47 30 -64
21 -16 -9 -21 -24 -24 -83 -3 -40 -3 -79 0 -85 2 -7 20 -15 39 -19 97 -18 264
37 331 110 57 62 66 83 66 156 0 96 -38 154 -117 179 -33 10 -145 20 -234 21
-59 0 -93 23 -93 63 0 63 87 85 188 47 68 -26 80 -25 88 6 9 35 -13 72 -58
100 -45 28 -86 30 -110 3z"/>
<path d="M5204 6386 c-11 -9 -16 -24 -15 -47 6 -73 0 -96 -50 -193 -75 -147
-113 -202 -148 -217 -60 -27 -52 -67 17 -91 26 -10 108 -53 182 -98 132 -79
135 -80 201 -80 61 0 72 3 110 33 55 42 89 106 89 169 0 41 -5 54 -37 90 -21
24 -42 46 -48 50 -5 4 14 8 43 8 65 0 95 19 116 71 27 68 16 120 -36 164 -46
40 -73 49 -148 50 l-75 2 -3 29 c-5 43 -19 54 -71 54 -25 0 -60 4 -78 9 -22 7
-37 5 -49 -3z m262 -165 c37 -23 50 -61 32 -99 -24 -48 -103 -55 -158 -12
l-25 19 27 55 c25 51 31 56 60 56 18 0 47 -9 64 -19z m-125 -187 c76 -42 96
-101 59 -174 -26 -51 -55 -70 -109 -70 -55 0 -101 37 -101 82 0 39 77 188 97
188 3 0 27 -12 54 -26z"/>
<path d="M1665 6214 c-55 -40 -137 -94 -182 -120 -68 -38 -83 -51 -83 -71 0
-19 9 -28 44 -43 44 -19 64 -43 165 -208 14 -24 41 -66 60 -95 26 -39 36 -66
40 -112 5 -53 9 -60 29 -63 15 -2 38 9 65 31 49 40 154 105 249 155 94 49 118
74 118 122 0 74 -40 130 -92 130 -32 0 -43 -17 -53 -85 -14 -99 -61 -155 -131
-155 -39 0 -63 20 -108 90 -43 65 -44 74 -11 96 21 14 31 14 74 3 41 -10 53
-10 66 1 18 15 12 44 -15 75 -10 11 -27 39 -38 61 -12 23 -28 44 -37 48 -23 8
-55 -29 -55 -64 0 -30 -37 -80 -59 -80 -6 0 -24 20 -41 44 -42 62 -42 114 1
150 35 30 52 35 134 38 52 3 61 6 74 29 29 53 -10 100 -82 98 -23 -1 -59 -21
-132 -75z"/>
<path d="M3315 6015 c-523 -60 -1014 -262 -1442 -593 -83 -63 -331 -302 -382
-367 -154 -197 -246 -341 -335 -525 -107 -220 -173 -429 -212 -670 -22 -136
-30 -469 -14 -605 29 -262 109 -540 222 -774 164 -343 445 -683 762 -926 371
-284 835 -478 1316 -549 596 -89 1210 8 1741 274 282 141 491 292 715 515 381
380 617 840 701 1371 24 154 24 537 0 684 -45 266 -108 462 -227 705 -233 477
-635 883 -1130 1143 -334 175 -650 274 -1017 317 -156 18 -539 18 -698 0z
m590 -85 c738 -67 1386 -389 1838 -914 124 -144 286 -384 327 -487 8 -19 17
-36 20 -39 4 -3 32 -68 63 -145 187 -467 213 -996 71 -1479 -20 -66 -44 -138
-54 -161 -9 -22 -21 -51 -25 -65 -12 -38 -120 -252 -161 -317 -174 -277 -321
-441 -612 -684 -12 -11 -64 -48 -115 -83 -307 -211 -639 -351 -1022 -431 -247
-52 -631 -70 -875 -41 -561 67 -1024 255 -1440 586 -437 347 -746 837 -859
1362 -88 410 -53 880 96 1273 65 173 208 432 321 579 191 251 437 475 707 644
50 31 318 167 385 196 283 119 586 189 905 209 120 8 329 6 430 -3z"/>
<path d="M2183 4352 c-11 -26 -28 -69 -38 -97 -20 -56 -119 -314 -139 -360 -7
-16 -23 -57 -35 -90 -25 -66 -109 -285 -147 -380 -39 -101 -74 -197 -74 -206
0 -5 50 -9 110 -9 l110 0 1 -27 c0 -16 0 -278 0 -583 0 -305 2 -570 5 -588 l6
-32 227 2 226 3 3 613 2 612 110 0 c61 0 110 3 110 8 0 11 -152 420 -189 507
-9 21 -17 41 -74 190 -115 300 -159 412 -167 427 -6 10 -10 24 -10 32 0 33
-18 22 -37 -22z"/>
<path d="M5184 4379 c-6 -8 -17 -30 -24 -49 -7 -19 -19 -48 -26 -65 -7 -16
-49 -127 -94 -245 -45 -118 -110 -287 -145 -375 -35 -88 -66 -171 -70 -185 -4
-14 -16 -44 -27 -67 -11 -23 -30 -73 -43 -110 l-23 -68 112 -5 111 -5 3 -612
2 -613 230 0 230 0 2 613 3 612 113 3 c61 1 112 6 112 11 0 7 -40 112 -60 156
-31 70 -170 441 -170 455 0 9 -4 20 -9 26 -9 8 -79 184 -152 379 -50 134 -63
159 -75 144z"/>
<path d="M2556 2211 c-40 -26 -55 -57 -56 -109 0 -103 87 -160 176 -113 90 48
96 169 11 221 -41 25 -94 25 -131 1z"/>
<path d="M2840 2192 c-34 -34 -40 -46 -40 -83 0 -56 30 -106 75 -125 46 -19
74 -18 112 6 71 43 84 144 25 203 -23 23 -42 31 -81 35 -49 4 -53 3 -91 -36z"/>
<path d="M3163 2210 c-40 -24 -66 -83 -58 -128 8 -42 58 -99 95 -107 38 -8 91
9 120 40 27 29 38 102 20 145 -26 63 -115 88 -177 50z"/>
<path d="M3463 2210 c-38 -25 -57 -77 -50 -132 14 -101 133 -140 206 -68 51
52 53 120 3 180 -37 43 -109 52 -159 20z"/>
<path d="M3785 2218 c-66 -36 -90 -103 -62 -170 57 -135 251 -76 234 71 -9 83
-104 137 -172 99z"/>
<path d="M4078 2214 c-52 -28 -76 -108 -49 -170 18 -44 59 -69 111 -69 74 0
119 46 122 125 3 97 -99 161 -184 114z"/>
<path d="M4365 2198 c-32 -29 -38 -42 -42 -84 -5 -61 21 -108 74 -130 99 -42
198 55 164 159 -17 52 -53 79 -110 85 -45 4 -52 2 -86 -30z"/>
<path d="M4683 2210 c-67 -40 -78 -141 -22 -199 52 -55 132 -50 184 11 19 23
25 41 25 80 0 45 -4 54 -39 89 -32 32 -46 39 -77 39 -22 0 -54 -9 -71 -20z"/>
<path d="M1181 5868 c-13 -18 -48 -52 -76 -76 -66 -53 -65 -77 4 -95 39 -10
64 -30 170 -137 68 -69 134 -138 147 -153 42 -50 23 -107 -36 -107 -20 0 -36
10 -58 37 -27 34 -31 36 -47 23 -26 -22 -47 -78 -40 -109 5 -26 4 -26 -33 -23
-60 6 -68 -11 -53 -111 10 -63 10 -89 1 -112 -34 -88 -95 -99 -195 -32 -35 23
-64 49 -64 58 -1 27 42 59 80 59 39 0 56 9 59 30 5 31 -1 40 -32 56 -18 9 -50
32 -71 50 -42 38 -49 40 -67 19 -9 -10 -10 -31 -6 -65 7 -55 -14 -117 -37
-104 -106 61 -132 109 -96 174 21 37 81 80 113 80 42 0 74 78 44 108 -16 16
-99 15 -118 -1 -8 -7 -50 -66 -93 -132 -43 -66 -86 -129 -95 -140 -82 -98 -84
-102 -56 -129 9 -10 33 -16 59 -16 40 0 57 -9 157 -82 219 -158 215 -154 230
-200 12 -34 20 -44 41 -46 19 -2 30 4 43 25 63 103 117 179 179 253 80 95 95
132 71 179 -9 16 -16 31 -16 33 0 2 26 3 58 3 48 0 64 6 102 32 86 59 130 126
130 199 0 62 -10 76 -156 226 -139 143 -143 149 -157 206 -10 39 -16 47 -37
50 -18 1 -31 -7 -49 -30z"/>
<path d="M5994 5888 c-4 -6 -7 -31 -5 -55 l2 -44 -88 -40 c-119 -55 -116 -53
-221 -99 -51 -22 -108 -40 -125 -40 -18 0 -38 -5 -45 -12 -19 -19 -14 -56 7
-63 10 -3 39 -26 64 -52 38 -38 50 -45 71 -39 23 6 26 11 26 50 0 40 4 47 34
65 18 12 40 21 47 21 8 0 49 -30 91 -65 71 -61 76 -68 68 -94 -14 -48 -30 -71
-51 -71 -34 0 -59 -19 -59 -45 0 -17 12 -33 44 -54 24 -17 56 -45 71 -63 15
-18 36 -39 47 -46 31 -22 54 7 61 76 3 31 38 172 78 312 40 140 74 269 76 286
5 30 4 30 -75 57 -88 30 -107 32 -118 15z m-16 -275 c-12 -38 -24 -72 -28 -77
-10 -10 -102 73 -94 86 8 12 127 67 135 62 4 -2 -2 -34 -13 -71z"/>
<path d="M6494 5402 c-18 -41 -35 -58 -140 -132 -206 -147 -222 -156 -263
-150 -26 3 -59 -10 -65 -26 -6 -16 9 -47 36 -75 21 -22 99 -135 153 -222 26
-43 57 -82 68 -88 49 -27 169 18 181 66 12 49 -6 62 -94 67 -72 4 -84 8 -110
33 -23 22 -30 38 -30 67 0 46 0 46 189 179 124 87 149 100 185 101 81 1 98 35
45 91 -17 17 -45 56 -61 85 -40 70 -66 71 -94 4z"/>
<path d="M401 3619 c-64 -12 -111 -48 -151 -114 -30 -51 -34 -66 -34 -129 -1
-116 50 -192 161 -240 97 -43 227 13 282 122 28 55 32 159 7 217 -27 63 -101
133 -150 140 -23 4 -48 8 -56 10 -8 1 -35 -1 -59 -6z"/>
<path d="M6783 3611 c-98 -34 -158 -126 -157 -242 1 -77 20 -122 72 -174 52
-51 96 -70 167 -69 94 0 159 42 206 133 70 136 -1 313 -143 356 -65 19 -81 19
-145 -4z"/>
<path d="M1 2590 l-1 -166 61 -149 c144 -355 372 -726 609 -990 57 -63 290
-297 350 -350 62 -56 249 -200 345 -267 77 -54 242 -158 250 -158 2 0 28 -14
57 -32 260 -154 646 -306 1000 -392 348 -85 299 -81 993 -81 l620 0 140 28
c480 96 885 245 1275 470 98 56 209 125 220 137 3 3 39 28 80 55 90 60 76 50
230 176 341 281 621 612 837 989 122 213 123 215 122 342 l0 113 -20 -40 c-10
-22 -41 -88 -68 -147 -219 -472 -580 -916 -1026 -1261 -420 -325 -935 -571
-1450 -692 -173 -41 -183 -43 -315 -64 -447 -72 -975 -65 -1400 18 -478 95
-902 255 -1295 490 -121 73 -303 198 -380 261 -23 19 -64 53 -91 75 -256 212
-521 509 -698 783 -198 308 -322 584 -425 947 l-19 70 -1 -165z"/>
<path d="M6403 2440 c-13 -5 -24 -13 -24 -17 -1 -4 -3 -18 -4 -31 -1 -13 -16
-38 -33 -55 -52 -54 -67 -129 -37 -186 18 -35 11 -43 -31 -35 -17 3 -41 1 -53
-5 -11 -6 -54 -62 -96 -124 -41 -61 -93 -132 -115 -157 -22 -25 -40 -49 -41
-55 -1 -5 -2 -19 -3 -30 -1 -14 -11 -21 -38 -26 -41 -6 -101 -48 -120 -82 -6
-12 -17 -40 -24 -62 -8 -26 -17 -39 -25 -36 -45 14 -63 6 -74 -31 -14 -49 -28
-63 -52 -50 -15 8 -32 1 -86 -35 -84 -56 -87 -77 -16 -107 19 -8 42 -23 50
-33 33 -37 197 -289 204 -311 13 -45 -3 -90 -43 -117 -46 -31 -82 -32 -119 -1
-48 41 -224 311 -229 353 -13 98 -22 102 -87 49 -51 -40 -90 -61 -137 -71 -19
-4 -56 -23 -81 -41 -77 -56 -304 -149 -414 -170 -19 -3 -39 -12 -45 -20 -15
-17 -4 -51 21 -64 10 -6 27 -27 38 -48 20 -41 66 -221 57 -229 -9 -9 -183 120
-295 220 -48 43 -70 57 -85 53 -12 -3 -45 -11 -73 -18 -48 -11 -53 -15 -53
-39 0 -18 10 -34 33 -52 29 -22 38 -40 62 -124 15 -54 38 -135 51 -179 24 -82
30 -161 15 -199 -18 -44 24 -69 72 -43 12 7 40 14 62 17 54 8 59 39 14 86 -31
31 -65 125 -94 254 -12 56 13 42 245 -143 160 -127 165 -130 195 -122 35 9 33
47 -11 198 -37 125 -84 296 -84 304 0 2 11 4 25 4 28 0 45 25 45 69 0 29 22
61 41 61 25 0 66 -63 144 -224 69 -142 84 -181 79 -205 -15 -73 19 -101 74
-62 15 10 52 27 82 38 45 17 56 25 58 46 3 21 -5 31 -38 52 -38 24 -51 44
-132 212 -77 158 -89 190 -82 212 13 35 40 38 92 10 l43 -24 21 28 c11 15 24
27 28 27 5 0 56 -70 115 -155 126 -184 155 -210 234 -209 61 0 108 20 175 73
91 73 115 153 71 236 -25 46 -113 183 -125 195 -14 13 -80 120 -80 129 0 13 5
14 52 1 27 -8 59 -35 125 -108 143 -155 150 -164 158 -210 11 -69 34 -72 98
-10 30 29 66 62 81 73 19 15 24 26 20 43 -4 12 -11 22 -18 22 -40 0 -90 26
-130 68 -50 51 -57 79 -26 107 25 23 44 17 160 -46 118 -64 158 -71 194 -36
33 33 34 87 3 142 -21 37 -67 62 -67 36 0 -21 -40 -20 -104 4 -36 14 -83 25
-102 25 -33 0 -36 2 -29 23 15 48 17 127 5 157 -7 17 -29 44 -49 60 l-36 30
36 0 c30 0 62 -20 205 -128 129 -97 171 -135 179 -158 13 -40 33 -64 54 -64 9
0 42 39 78 93 34 50 83 115 108 142 73 79 98 120 92 152 -13 66 -13 68 12 71
34 5 133 107 161 167 34 71 39 168 11 222 -45 89 -153 147 -230 122 -32 -10
-145 -80 -210 -131 -13 -10 -39 -18 -57 -18 -62 0 -83 75 -34 118 11 9 50 28
87 42 59 22 68 29 68 50 0 16 -9 29 -25 37 -29 15 -104 16 -137 3z m417 -277
c34 -23 40 -33 40 -69 0 -59 -66 -109 -137 -103 -47 4 -62 0 -72 -19 -8 -15
-1 -29 34 -72 24 -30 42 -56 40 -58 -2 -2 -17 5 -33 17 -35 24 -61 27 -87 7
-17 -12 -18 -18 -7 -66 25 -107 8 -179 -45 -197 -39 -14 -73 -2 -143 49 -85
61 -77 98 24 108 46 4 51 8 54 31 3 22 -5 32 -46 58 -27 17 -57 40 -67 51 -21
24 -60 26 -69 5 -3 -9 0 -35 6 -59 10 -38 9 -48 -6 -70 -14 -23 -20 -25 -38
-15 -12 7 -33 22 -47 35 -14 13 -29 24 -32 24 -13 0 -31 60 -25 83 9 39 55 76
113 93 46 14 55 20 60 45 7 35 11 35 63 8 62 -32 112 -23 200 37 148 102 172
110 220 77z m-855 -628 c16 -17 25 -37 25 -59 0 -44 -29 -126 -45 -126 -11 0
-89 84 -108 117 -9 15 10 65 30 81 27 20 70 14 98 -13z"/>
<path d="M1081 2037 c-6 -8 -14 -29 -17 -48 -5 -25 -29 -55 -93 -117 -173
-169 -224 -210 -266 -217 -22 -4 -46 -12 -52 -18 -22 -16 -15 -53 12 -67 14
-8 35 -29 46 -47 34 -54 89 -38 89 27 0 20 32 55 168 184 28 28 54 47 57 44 3
-2 -4 -40 -16 -84 -12 -43 -24 -98 -26 -121 l-5 -43 33 0 c19 0 76 9 128 20
52 11 97 17 100 14 6 -5 -103 -110 -184 -178 -22 -18 -56 -36 -76 -40 -67 -12
-76 -56 -20 -89 16 -9 42 -37 58 -62 32 -51 39 -56 70 -44 15 5 23 20 28 48 7
45 26 65 205 231 107 100 148 127 174 117 23 -9 19 -56 -7 -93 -12 -17 -46
-67 -76 -110 -30 -44 -57 -81 -61 -84 -4 -3 -26 -34 -50 -70 -79 -116 -64
-197 53 -291 68 -54 127 -74 194 -65 40 5 55 14 93 54 25 26 58 68 75 92 53
80 147 205 167 222 63 56 48 -12 -39 -179 -94 -182 -128 -233 -165 -247 -29
-11 -48 -50 -32 -66 5 -4 34 -18 64 -31 30 -13 107 -54 170 -91 94 -57 120
-68 145 -63 36 7 93 59 112 103 12 30 12 37 -2 58 -20 31 -36 30 -85 -2 -24
-16 -59 -29 -88 -31 -41 -4 -50 -1 -71 21 -13 14 -23 36 -23 50 0 26 40 113
118 256 60 111 79 137 111 152 59 29 55 71 -8 93 -37 13 -203 117 -214 134 -3
6 -19 11 -35 11 -27 0 -29 -3 -36 -56 -7 -59 0 -48 -163 -267 -95 -128 -116
-138 -192 -95 -29 16 -42 32 -51 59 -10 34 -8 42 17 86 24 40 104 153 197 275
11 15 39 33 62 41 33 11 41 18 41 38 0 18 -11 30 -45 48 -25 14 -87 67 -138
118 -51 51 -100 93 -110 93 -9 0 -67 -10 -129 -22 -62 -12 -116 -18 -120 -15
-3 4 8 58 25 120 18 63 32 121 32 129 0 20 -104 128 -123 128 -8 0 -20 -6 -26
-13z"/>
<path d="M2240 1074 c-48 -44 -55 -64 -35 -104 19 -37 40 -38 85 -5 37 28 60
31 78 13 19 -19 15 -40 -27 -156 -22 -59 -52 -143 -67 -187 -21 -61 -37 -89
-67 -116 -57 -53 -50 -79 29 -99 33 -9 71 -24 84 -33 53 -37 89 -6 70 61 -10
33 -8 49 29 152 103 297 104 300 147 300 28 0 42 -18 52 -66 6 -32 12 -40 34
-42 40 -5 66 14 74 53 8 46 24 35 24 -17 0 -40 -83 -376 -101 -410 -5 -9 -22
-30 -39 -46 -52 -52 -38 -76 51 -86 31 -4 65 -14 75 -21 46 -35 94 -2 74 50
-16 43 -12 80 29 234 22 80 44 165 50 187 7 25 26 56 47 77 30 29 35 40 30 61
-5 21 -12 26 -39 26 -17 1 -57 9 -87 20 -67 24 -89 25 -96 5 -8 -19 -24 -20
-24 -1 0 27 -29 44 -107 61 -89 20 -148 42 -247 91 -38 19 -73 34 -77 34 -5 0
-27 -16 -49 -36z"/>
<path d="M3131 842 c-9 -16 -6 -30 14 -69 14 -26 56 -169 95 -317 39 -148 75
-277 81 -288 8 -15 16 -18 32 -13 12 4 45 12 72 17 28 6 56 12 63 14 8 2 12
23 12 58 0 44 16 95 82 268 77 202 99 248 120 248 13 0 56 -51 66 -80 6 -14
20 -113 32 -220 21 -183 22 -198 6 -227 -21 -41 -20 -72 1 -80 9 -4 55 2 102
11 47 10 133 20 191 23 137 6 168 16 186 59 19 44 18 114 -2 142 -9 14 -26 22
-43 22 -22 0 -32 -8 -47 -37 -39 -76 -86 -113 -143 -113 -50 0 -79 35 -91 110
-17 112 -16 120 25 120 26 0 43 -8 65 -30 51 -52 87 -29 70 46 -5 21 -10 56
-10 76 0 51 -9 68 -34 68 -15 0 -31 -13 -47 -37 -26 -40 -72 -64 -89 -47 -6 6
-11 42 -13 81 -2 64 0 73 22 92 40 35 84 37 145 6 62 -31 100 -29 117 9 14 30
-8 76 -45 96 -23 12 -46 10 -189 -13 -89 -15 -181 -27 -204 -27 -23 0 -45 -5
-48 -11 -5 -7 -12 -6 -23 4 -10 9 -49 17 -95 20 -95 7 -108 -5 -75 -71 l21
-41 -30 -88 c-95 -274 -105 -294 -116 -236 -2 15 -21 89 -41 163 -39 147 -44
196 -21 226 18 23 19 64 3 65 -7 1 -40 2 -73 3 -33 1 -76 6 -97 10 -31 7 -38
5 -47 -12z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
